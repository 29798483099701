import React, { Fragment } from "react";
import Error from "../../components/404";

const ErrorPage = () => {
  return (
    <Fragment>
      <Error />
    </Fragment>
  );
};
export default ErrorPage;
