import React from 'react'
import Logo from '../../images/logo.png'
import {Link}  from 'react-router-dom'
import HeaderTopbar from '../HeaderTopbar'
import MobileMenu from '../../components/MobileMenu'
import './style.css'

const Header = () => {
    const SubmitHandler = (e) =>{
        e.preventDefault()
     }

     const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(	
        <div className="middle-header">
            <HeaderTopbar/>
            <div className="header-style-3">
                <div className="container">
                    <div className="header-content">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-3 col-md-4 col-sm-4 col-4 d-flex align-items-center">
                            <div className="logo">
                                <Link onClick={ClickHandler} to="/" title=""><img src={Logo} alt=""/></Link>
                            </div>
                        </div>
                        <div className="col-lg-9 d-lg-block d-none">
                            <nav>
                                <ul>
                                <li><Link onClick={ClickHandler} to="/" title="">Home</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about" title="">About Us</Link></li>
                                    {/* <li><Link onClick={ClickHandler} to="/gallery" title="">Gallery</Link></li> */}
                                    {/* <li><Link onClick={ClickHandler} to="/committee-members" title="">Committee Members</Link></li> */}
                                    <li><Link onClick={ClickHandler} to="/community-services" title="">Community Services</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact" title="">Contact Us</Link></li>
                                </ul>
                            </nav>
                        </div>
                       
                        <div className="col-md-2 col-sm-2 col-2 d-flex justify-content-end">
                            <MobileMenu/>
                        </div>
                    </div>
                    
                        <div className="clearfix"></div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Header;