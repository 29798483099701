import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
import "./style.css";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleCall = () => {
    // window.location.href = 'tel:+919082926600';
  };

  const handleMailTo = () => {
    // window.location.href = 'nn_khan09@yahoo.co.in';
  };

  return (
    <div className={`wpo-ne-footer ${props.footerClass}`}>
      <footer className="wpo-site-footer">
        <div className="wpo-upper-footer">
          <div className="container">
            <div className="row">
              <div className="col col-lg-5 col-md-6 col-12">
                <div className="widget about-widget">
                  <div className="logo widget-title">
                    <img src={Logo} alt="" />
                  </div>
                  <p>
                    Jamiatul Muslimeen, established in 1945, has been serving
                    the community with dedication for over seven decades.
                  </p>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-google"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="col col-lg-3 col-md-6 col-12">
                <div className="widget link-widget">
                  <div className="widget-title">
                    <h3>Service</h3>
                  </div>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/service-single">
                        Islamic School
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/service-single">
                        Our Causes
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/service">
                        Our Service
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/event">
                        Our Event
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="col col-lg-2 col-md-6 col-12">
                <div className="widget link-widget">
                  <div className="widget-title">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        Home
                      </Link>
                    </li>
                    {/* <li>
                      <Link onClick={ClickHandler} to="/service-single">
                        Community Services
                      </Link>
                    </li> */}
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col col-lg-4 offset-lg-1 col-md-6 col-12">
                <div className="widget market-widget wpo-service-link-widget">
                  <div className="widget-title">
                    <h3>Contact </h3>
                  </div>
                  <div className="contact-ft">
                    <ul>
                      <li className="d-flex">
                        <i className="fi ti-location-pin"></i>Opp. Dargah Masjid, Dargah Road, Amrut Nagar, Mumbra, Thane 400612
                      </li>
                      <li onClick={handleCall}>
                        <i className="fi flaticon-call"></i>+91 9082926600
                      </li>
                      <li onClick={handleMailTo}>
                        <i className="fi flaticon-envelope"></i>
                        nn_khan09@yahoo.co.in
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wpo-lower-footer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 d-flex p-0 justify-content-md-start justify-content-lg-start justify-content-center">
                <p className="copyright">
                  &copy; {new Date().getFullYear()}, All rights reserved
                </p>
              </div>
              <div className="col-12 col-md-6 d-flex p-0 justify-content-md-end justify-content-lg-end justify-content-center">
                <p>
                  Powered By{" "}
                  <a
                    style={{ color: "#009846" }}
                    target="_blank"
                    href="https://www.weblabs.studio"
                  >
                    Weblabs Studio
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
