import React, { useState, useEffect } from "react";
import PaymentQR from "../../images/payment-qr.png";
import "./style.css";

const Donate = (props) => {
  const [activeTab, setActiveTab] = useState("upi");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");

    if (type === "acDetails") {
      setActiveTab("acDetails");
      // scrollToTabs()
    } else if (type === "upi") {
      setActiveTab("upi");
      // scrollToTabs()
    }
  }, []);

  const scrollToTabs = () => {
    window.scrollBy({
      top: 300,
      behavior: 'smooth'
  });
  }

  return (
    <div className="wpo-donation-page-area section-padding">
      <div className="container d-flex justify-content-center">
        <div  className="donation-tabs">
          <ul className="nav nav-tabs d-flex justify-content-center border-0">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "upi" ? "active active-donation-tab" : ""
                } border-0 text-center`}
                href="#upi"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("upi");
                }}
              >
                UPI / Qr Code
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "acDetails" ? "active active-donation-tab" : ""
                } border-0 text-center`}
                href="#acDetails"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("acDetails");
                }}
              >
                A/C Details
              </a>
            </li>
          </ul>
          <div className="tab-content" style={{ marginTop: "20px" }}>
            <div
              className={`tab-pane ${activeTab === "upi" ? "active" : ""}`}
              id="upi"
            >
              <div className="d-flex justify-content-center">
                <img className="payment-qr" src={PaymentQR} />
              </div>
            </div>
            <div
              className={`tab-pane ${
                activeTab === "acDetails" ? "active" : ""
              }`}
              id="acDetails"
            >
              <p className="mb-0 text-dark">
                <strong>Dargah Madarasa Trust</strong>
              </p>
              <p className="mb-0 text-dark">
                <strong>DCB Bank</strong>
              </p>
              <p className="mb-0 text-dark">
                <strong>Branch</strong> Mumbra
              </p>
              <p className="mb-0 text-dark">
                <strong>A/c No.</strong> 01211100005630
              </p>
              <p className="mb-0 text-dark">
                <strong>IFSC.</strong> DCBL0000012
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;
