import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import AboutPage from '../AboutPage'
import GalleryPage from '../Gallery';
import CommunityServicesPage from '../CommunityServices';
import DonatePage from '../DonatePage'
import ErrorPage from '../ErrorPage'  
import ContactPage from '../ContactPage' 
      

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage2}/>
            <Route path='/about' component={AboutPage} />
            <Route path='/gallery' component={GalleryPage} />
            <Route path='/community-services' component={CommunityServicesPage} />
            <Route path='/donate' component={DonatePage}/>
            <Route path='/contact' component={ContactPage}/>
            <Route path='*' component={ErrorPage}/>
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
