import React, { Component } from "react";
import DummyQr from "../../images/dummy-qr.png";
import PaymentQR from "../../images/payment-qr.png";
import { Link } from "react-router-dom";
import "./style.css";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

class PaymentInfo extends Component {
  render() {
    return (
      <div className="payment-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="payment-text">
                {/* <span>Donate Now</span> */}
                <h2 style={{ color: "#007336" }}>Support Our Cause</h2>
                <p style={{ color: "black" }}>
                  Since foundation in 1945, we have proudly overseen the
                  maintenance and management of sacred spaces including{" "}
                  <strong>Dargah Masjid</strong>, the{" "}
                  <strong>Sunni Muslim Qabrastan</strong>, and esteemed{" "}
                  <strong>
                    Hazrat Sayyed Khwaja Fakhruddin Shah Baba Rahmatullah Alaih
                    Dargah
                  </strong>
                  . Our mission extends beyond the preservation of these holy
                  sites, we strive to knit our community closer, provide support
                  to those in need, and uphold our precious traditions. Your
                  generous donations empower us to continue this noble legacy,
                  ensuring that these sanctuaries remain places of solace and
                  prayer for all. Join us in our commitment to nurture and
                  sustain these pillars of our faith and culture.
                </p>
              </div>

              <div className="payment-text mt-4">
              <Link
                  onClick={ClickHandler}
                  to="/donate?type=acDetails"
                  className="theme-btn-s3"
                >
                 View A/c Details
                </Link>
                {/* <span style={{ color: "#007336" }}>Donate Now</span>
                <p className="mb-0 text-dark"><strong>Dargah Madarasa Trust</strong></p>
                <p className="mb-0 text-dark"><strong>DCB Bank</strong></p>
                <p className="mb-0 text-dark"><strong>Branch Mumbra</strong> </p>
                <p className="mb-0 text-dark"><strong>A/c No.01211100005630</strong> </p>
                <p className="mb-0 text-dark"><strong>IFSC. DCBL0000012</strong></p> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="payment-text">
              <div className="d-flex justify-content-center">
                <img className="payment-qr2" src={PaymentQR} />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PaymentInfo;
