import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import Hero2 from "../../components/hero2";
import About from "../../components/about";
import Gallery from "../../components/Gallery";
import CommunityServices from "../../components/CommunityServices";
import PaymentInfo from "../../components/PaymentInfo";
import Support from "../../components/Support";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";

const HomePage = () => {
  return (
    <Fragment>
      <Navbar />
      <Hero2 />
      <About isKnowMore={true} />
      <Gallery />
      <PaymentInfo />
      <CommunityServices EventClass={"wpo-event-area-2"} />
      <Support />
      <Footer footerClass={"wpo-ne-footer-2"} />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
