import React from "react";
import { Link } from "react-router-dom";

import img1 from "../../images/gallery/img1.jpg";
// import img2 from "../../images/gallery/img2.jpg";
import img3 from "../../images/gallery/img3.jpg";
import img4 from "../../images/gallery/img4.jpg";
import img5 from "../../images/gallery/img5.jpg";
import "./style.css";

const CommunityServices = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const eventList = [
    {
      eventImg: img5,
      title: "Hazrat Sayyed Khwaja Fakhruddin Shah Baba Rahmatullah Alaih Dargah",
      location: "Mumbra, Maharashtra",
    },
    {
      eventImg: img1,
      title: "Dargah Masjid",
      location: "Mumbra, Maharashtra",
    },
    {
      eventImg: img4,
      title: "Sunni Muslim Qabrastan",
      location: "Mumbra, Maharashtra",
    },
  ];

  return (
    <div className={`wpo-event-area section-padding  ${props.EventClass}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div style={{ maxWidth: "none" }} className="wpo-section-title">
              <span>Community Services</span>
              <h2>Serving Qabrastan, Mosque & Dargah</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {eventList.map((item, en) => (
            <div className="col-lg-6 col-sm-12 col-12 custom-grid" key={en}>
              <div className="wpo-event-item d-flex flex-column">
                <div className="wpo-event-img services-img-container">
                  <img style={{height: '100%', objectFit: 'cover'}} src={item.eventImg} alt="" />
                </div>
                <div className="wpo-event-text">
                  <h2 className="text-center">{item.title}</h2>
                  {/* <ul>
                    <li>
                      <i className="fa fa-map-marker"></i>
                      {item.location}
                    </li>
                  </ul> */}
                  {/* <p>{item.des}</p>
                  <Link onClick={ClickHandler} to={item.link}>
                    Know More
                  </Link> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommunityServices;
