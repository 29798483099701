import React from "react";
import { Link } from "react-router-dom";
import presidentImage from "../../images/committee/president-nayeem-khan.png";
import "./style.css";

const About = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className="wpo-about-area section-padding pb-0">
      <div className="container">
        <div className="wpo-about-wrap">
          <div className="row">
            <div className="col-lg-8 col-md-12 colsm-12">
              <div className="wpo-about-text">
                <div className="wpo-section-title">
                  <span>About Us</span>
                  <h2>Serving Community for Over 50 Years</h2>
                </div>

                <p style={{color: 'black'}}>
                  Since our establishment in 1945, Jamiatul-Muslimeen has been a
                  pivotal force within our community, dedicated to the
                  stewardship of the <strong>Dargah Masjid</strong>,{" "}
                  <strong>Sunni Muslim Qabrastan</strong>, and the{" "}
                  <strong>
                    Hazrat Sayyed Khwaja Fakhruddin Shah Baba Rahmatullah Alaih
                    Dargah
                  </strong>
                  . Our mission transcends mere preservation; it is a commitment
                  to fostering unity, extending support wherever needed, and
                  ensuring the vibrancy of our traditions for generations to
                  come.
                </p>

                <p style={{color: 'black'}}>
                  Under the visionary leadership of our President,{" "}
                  <strong>Naymeen Khan</strong>, over the last 35 years,
                  Jamiatul-Muslimeen has witnessed remarkable development and
                  growth. Our initiatives have expanded, reaching more hearts
                  and homes, as we continue to build on the foundation of faith,
                  community service, and cultural preservation. This era of
                  leadership has not only solidified our legacy but has also set
                  a forward-looking path for the future.
                </p>

                {props.isKnowMore && (
                  <div className="btns">
                    <Link
                      onClick={ClickHandler}
                      to="/about"
                      className="theme-btn"
                      tabIndex="0"
                    >
                      Know More
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="wpo-about-img-3">
                <img src={presidentImage} alt="" />
                <h6 className="text-center mt-2">Nayeem Khan - President</h6>
                <p style={{color: 'darkgreen'}} className="text-center">
                  Serving as President for the Last 35 Years
                </p>
              </div>
            </div>
          </div>

          {!props.isKnowMore && (
            <>
            <div className="row">
              <div className="col-lg-12 col-md-12 colsm-12">
                <div className="wpo-about-text">
                  <div
                    style={{ maxWidth: "none" }}
                    className="wpo-section-title"
                  >
                    <span style={{color: '#007336'}}>
                    Jamiatul Muslimeen Committee Members
                    </span>
                  </div>

                  <ul style={{lineHeight: '30px'}}>
                    <li>• <span className="text-primary-green font-weight-bold">President</span>: Haji Nayeem Nazir Khan</li>
                    <li>• <span className="text-primary-green font-weight-bold">V. President</span>: Haji Abba Mohd. N. Memon</li>
                    <li>• <span className="text-primary-green font-weight-bold">Treasurer</span>: Munaf A. Siddiqui</li>
                    <li>• <span className="text-primary-green font-weight-bold">Gen. Sec.</span>: Yasin Ayyub Qureshi</li>
                    <li>• <span className="text-primary-green font-weight-bold">Chairman Legal Advisor</span>: Sayyed Shamim Ahsan</li>
                    <li>• <span className="text-primary-green font-weight-bold">Chairman Kabrastan Comm.</span>: Fahad Farooque Kazi</li>
                    <li>• <span className="text-primary-green font-weight-bold">Chairman Fund Collection</span>: Salim Dawood Khan</li>
                    <li>• <span className="text-primary-green font-weight-bold">Chairman Muslim Wel. Committee</span>: Sayyed Wasim Ali Nazir Ali</li>
                    <li>• <span className="text-primary-green font-weight-bold">V. Chairman Muslim Wel. Comm.</span>: Imran AB. Rashid Qureshi</li>
                    <li>• <span className="text-primary-green font-weight-bold">Chairman URS Committee</span>: Haji Riyazuddin A. Qadiri</li>
                    <li>• <span className="text-primary-green font-weight-bold">V. Chairman URS Committee</span>: Mohammed Khalid Sami Malik</li>
                    <li>• <span className="text-primary-green font-weight-bold">V. Chairman URS Committee</span>: Salim Hasham Mansoori</li>
                  </ul>
                </div>


                <div className="wpo-about-text mt-5">
                  <div
                    style={{ maxWidth: "none" }}
                    className="wpo-section-title"
                  >
                    <span style={{color: '#007336'}}>
                    Dargah Masjid Committee Members
                    </span>
                  </div>

                  <ul style={{lineHeight: '30px'}}>
                    <li>• <span className="text-primary-green font-weight-bold">Chairman</span>: Haji Nayeem Nazir Khan</li>
                    <li>• <span className="text-primary-green font-weight-bold">Vice Chairman</span>: Haji Abba Mohd. N. Memon</li>
                    <li>• <span className="text-primary-green font-weight-bold">Treasurer</span>: Munaf A. Siddiqui</li>
                    <li>• <span className="text-primary-green font-weight-bold">General Secretary</span>: Meraj Nayeem Khan</li>
                    <li>• <span className="text-primary-green font-weight-bold">Propaganda Secretary</span>: Monis Munaaf Siddiqui</li>
                  </ul>
                </div>
              </div>
            </div>

            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
