import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const Support = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="support-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="support-text">
              <span>Support Us</span>
              <h2 style={{color: "#007336"}}>We Need Your Support</h2>
              <p style={{color: 'black'}}>
                We've Been Here Since 1945: Jamiatul-Muslimeen has always been
                there for our community, taking care of our mosques, graveyards,
                and dargahs. From the beginning, our goal has been simple: to
                bring people together, help out wherever we can, and keep our
                traditions strong.
              </p>

              <p style={{color: 'black'}}>
                We Can't Do It Without You: Your support means everything to us.
                It's what keeps us going and allows us to maintain these
                important places where we can all come together in faith and
                fellowship. When you give, you're not just donating; you're
                making sure that we can keep doing this important work for many
                more years to come.
              </p>
              <div className="btns">
                <Link
                  onClick={ClickHandler}
                  to="/donate"
                  className="theme-btn-s3"
                >
                  Donate Now
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6">
                        <div className="progress-area">
                            <div className="row">
                                <div className="col-lg-4 col-sm-4 custom-grid">
                                    <div className="progress-wrap">
                                        <div className="progress s1">
                                            <span className="progress-left">
                                                <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right">
                                                <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value">80%</div>
                                        </div>
                                        <span>Mosque</span>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-4 custom-grid">
                                    <div className="progress-wrap">
                                        <div className="progress s2">
                                            <span className="progress-left">
                                                <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right">
                                                <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value">80%</div>
                                        </div>
                                        <span>Dargah</span>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-4 custom-grid">
                                    <div className="progress-wrap">
                                        <div className="progress s3">
                                            <span className="progress-left">
                                                <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right">
                                                <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value">65%</div>
                                        </div>
                                        <span>Qabrastan</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Support;
