import React, { Component } from "react";

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    error: {},
  };

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();

    const { name, email, message, error } = this.state;

    if (name === "") {
      error.name = "Please enter your name";
    }
    if (email === "") {
      error.email = "Please enter your email";
    }
    if (message === "") {
      error.message = "Please enter message";
    }

    if (error) {
      this.setState({
        error,
      });
    }
    // if (error.name === "" && error.email === "" && error.message === "") {
    //   this.setState({
    //     name: "",
    //     email: "",
    //     message: "",
    //     error: {},
    //   });
    // }
    if (error.name === "" && error.email === "" && error.message === "") {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("message", message);
      formData.append("_captcha", false);

      // Replace with your actual endpoint
      const url = "https://formsubmit.co/nn_khan09@yahoo.co.in";

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            this.setState({
              name: "",
              email: "",
              message: "",
              error: {}, // Optionally reset errors or handle success feedback
            });
            // Handle successful submission feedback to user here if needed
          } else {
            // Handle server response errors here if needed
          }
        })
        .catch(() => {
          // Handle network errors or exceptions here if needed
        });
    }
  };

  render() {
    const { name, email, message, error } = this.state;

    return (
      <form onSubmit={this.submitHandler} className="form">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-field">
              <input
                value={name}
                onChange={this.changeHandler}
                type="text"
                name="name"
                placeholder="Name"
              />
              <p>{error.name ? error.name : ""}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-field">
              <input
                onChange={this.changeHandler}
                value={email}
                type="email"
                name="email"
                placeholder="Email"
              />
              <p>{error.email ? error.email : ""}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-field">
              <textarea
                onChange={this.changeHandler}
                value={message}
                name="message"
                placeholder="Message"
              ></textarea>
              <p>{error.message ? error.message : ""}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-submit">
              <button type="submit" className="theme-btn">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
export default ContactForm;
