import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import CommunityServices from '../../components/CommunityServices';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const CommunityServicesPage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Community Services'} pagesub={'Community Services'}/> 
            <CommunityServices />
            <Footer footerClass={'wpo-ne-footer-2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default CommunityServicesPage;
