import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./style.css";

const Scrollbar = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 800) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="col-lg-12">
      <div className="header-menu">
        <ul className="smothscroll">
          {isVisible && (
            <li>
              <AnchorLink href="#scroll">
                <i className="fa fa-arrow-up"></i>
              </AnchorLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Scrollbar;