import React, { useState } from "react";
import { Link } from "react-router-dom";
import img1 from "../../images/gallery/img1.jpg";
import img2 from "../../images/gallery/img2.jpg";
import img3 from "../../images/gallery/img3.jpg";
import img4 from "../../images/gallery/img4.jpg";
import "./style.css";

import { Lightbox } from "react-modal-image";

const Gallery = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleShowImageModal = (image) => {
    console.log("here");
    setCurrentImage(image);
    setShowModal(true);
  };

  const handleCloseImageModal = () => {
    setCurrentImage(null);
    setShowModal(false);
  };

  return (
    <>
      <div className="service-area-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="wpo-section-title">
                <span>Our Gallery</span>
                <h2>Glimpses of Our Work</h2>
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-md-4 p-1">
                <img
                  onClick={() => handleShowImageModal(img3)}
                  style={{
                    cursor: "pointer",
                    maxWidth: "100%",
                    height: "auto",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={img3}
                  alt="First Image"
                />
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6 p-1">
                    <img
                      onClick={() => handleShowImageModal(img1)}
                      style={{
                    cursor: "pointer",
                    maxWidth: "100%",
                    height: "auto",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                      src={img1}
                      alt="Second Image"
                    />
                  </div>
                  <div className="col-md-6 p-1">
                    <img
                      onClick={() => handleShowImageModal(img4)}
                      style={{
                    cursor: "pointer",
                    maxWidth: "100%",
                    height: "auto",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                      src={img4}
                      alt="Third Image"
                    />
                  </div>
                  <div className="col-md-6 p-1">
                    <img
                      onClick={() => handleShowImageModal(img2)}
                      style={{
                    cursor: "pointer",
                    maxWidth: "100%",
                    height: "auto",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                      src={img2}
                      alt="Fifth Image"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="btns d-flex justify-content-center mt-3">
            <Link
              onClick={ClickHandler}
              to="/gallery"
              className="theme-btn"
              tabIndex="0"
            >
              View More
            </Link>
          </div> */}
          </div>
        </div>
      </div>

      {showModal && (
        <Lightbox
          hideDownload={true}
          small={currentImage}
          large={currentImage}
          onClose={handleCloseImageModal}
        />
      )}
    </>
  );
};

export default Gallery;
