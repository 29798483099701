import React from 'react'
import {Link}  from 'react-router-dom'
import './style.css'

const HeaderTopbar = () => {
    return(	
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-md-8 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi flaticon-call"></i>+91 9082926600</li>
                                <li><i className="fi flaticon-envelope"></i>nn_khan09@yahoo.co.in</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-md-4 col-sm-12 col-12 header-donate-btn-col">
                        <div className="contact-info">
                            {/* <ul>
                                <li><Link to="/">Galley</Link></li>
                                <li><Link to="/signup">Sign Up</Link></li>
                                <li> */}
                                <Link className="theme-btn" to="/donate">Donate Now</Link>
                                {/* </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;