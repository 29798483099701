import React, { Component, createRef } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "About Us",
    link: "/about",
  },
  // {
  //   id: 3,
  //   title: "Gallery",
  //   link: "/gallery",
  // },
  {
    id: 4,
    title: "Community Services",
    link: "/community-services",
  },
  {
    id: 5,
    title: "Contact Us",
    link: "/contact",
  },
];

export default class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuShow: false,
      isOpen: 0,
    };
    this.menuRef = createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    // Ensure body scroll is enabled when component unmounts
    this.toggleBodyScroll(false);
  }

  handleClickOutside = (event) => {
    if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
      this.setState({ isMenuShow: false }, () => {
        // After state update, ensure body scroll is enabled
        this.toggleBodyScroll(false);
      });
    }
  };

  menuHandler = () => {
    this.setState((prevState) => ({
      isMenuShow: !prevState.isMenuShow,
    }), () => {
      // After state update, toggle body scroll
      this.toggleBodyScroll(this.state.isMenuShow);
    });
  };

  setIsOpen = (id) => () => {
    this.setState((prevState) => ({
      isOpen: id === prevState.isOpen ? 0 : id,
    }));
  };

  toggleBodyScroll(isMenuOpen) {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }

  render() {
    const { isMenuShow, isOpen } = this.state;

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };

    return (
      <div>
        <div
          ref={this.menuRef}
          className={`mobileMenu ${isMenuShow ? "show" : ""}`}
        >
          <ul className="responsivemenu">
            {menus.map((item) => (
              <li key={item.id}>
                {item.submenu ? (
                  <p onClick={this.setIsOpen(item.id)}>
                    {item.title}
                    {item.submenu ? (
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  <Link to={item.link}>{item.title}</Link>
                )}
                {item.submenu && (
                  <Collapse isOpen={item.id === isOpen}>
                    <Card>
                      <CardBody>
                        <ul>
                          {item.submenu.map((submenu) => (
                            <li key={submenu.id}>
                              <Link onClick={ClickHandler} to={submenu.link}>
                                {submenu.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="showmenu" onClick={this.menuHandler}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
      </div>
    );
  }
}
