import React from "react";
import ContactForm from "../ContactFrom";
import "./style.css";

const Contactpage = () => {
  return (
    <section className="wpo-contact-form-map section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="contact-form">
                  <h2>Get In Touch</h2>
                  <ContactForm />
                </div>
              </div>
              <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="contact-map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.4783760919486!2d73.0207639253695!3d19.174297298878997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bf115b013f2f%3A0x475ac9cab984c6dc!2sAmrut%20Nagar%2C%20Mumbra%2C%20Thane%2C%20Maharashtra%20400612!5e0!3m2!1sen!2sin!4v1709624805029!5m2!1sen!2sin"></iframe>
                </div>
              </div>
            </div>
            <div className="wpo-contact-info">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="info-item">
                    <div className="info-wrap">
                      <div className="info-icon d-flex justify-content-center">
                        <i className="ti-world"></i>
                      </div>
                      <div className="info-text mt-2">
                        <span>Office Address</span>
                        <p>Opp. Dargah Masjid, Dargah Road, Amrut Nagar, Mumbra, Thane 400612</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="info-item">
                    <div className="info-wrap">
                      <div className="info-icon-2 d-flex justify-content-center">
                        <i className="fi flaticon-envelope"></i>
                      </div>
                      <div className="info-text mt-2">
                        <span>Official Mail</span>
                        <p>nn_khan09@yahoo.co.in</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="info-item">
                    <div className="info-wrap">
                      <div className="info-icon-3 d-flex justify-content-center">
                        <i className="ti-headphone-alt"></i>
                      </div>
                      <div className="info-text mt-2">
                        <span>Official Phone</span>
                        <p>+91 90829-26600</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contactpage;
